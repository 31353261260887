/* eslint react/destructuring-assignment: 'off' */
/* eslint react/no-access-state-in-setstate: 'off' */
/* eslint no-unused-vars: 'off' */

import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import CsvDownload from 'react-json-to-csv';

const StorageDumpWrapper = styled.div`
  ol {
    text-align: left;
  }
`;

export default class DisplayLocalStorage extends React.Component {
  constructor() {
    super();
    this.state = {
      localStorageState: [],
    };
  }

  handleUpdate = () => {
    if (localStorage.getItem('entries') === '') {
      console.log('empty');
    } else {
      const localStorageValues = JSON.parse(localStorage.getItem('entries'));
      console.log(Object.values(localStorageValues));
      this.setState({
        localStorageState: Object.values(localStorageValues),
      });
    }
  };

  render() {
    return (
      <StorageDumpWrapper>
        <button type="button" onClick={this.handleUpdate}>
          Build CSV
        </button>
        <CsvDownload data={this.state.localStorageState} />
      </StorageDumpWrapper>
    );
  }
}
